import React from "react";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.log("err", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="flex min-h-screen flex-col items-center justify-center space-y-4">
          <h1 className=" text-4xl font-semibold capitalize">
            Something went wrong.
          </h1>

          <button className="btn" onClick={() => window.location.reload()}>
            refresh
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}
