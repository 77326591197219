import LoadingLogo from "../assets/LoadingLogo";

const Loading = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        flexDirection: "column",
      }}
      className="fixed left-0 top-0 z-50 flex h-screen w-full flex-col items-center justify-center  bg-gray-400 font-black opacity-90"
    >
      <LoadingLogo />
      {/* <h3 className="capitalize text-lg ml-5">
        <span className="text-red-600">fast</span>{" "}
        <span className="text-green-600">credit</span>
      </h3>
      <p className="ml-5 capitalize ">get it fast..</p> */}
    </div>
  );
};

export default Loading;
