import { createContext, useContext, useState } from "react";

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
  const [vendorProduct, setVendorProduct] = useState([]);
  const [bankID, setBankID] = useState("");
  const [show, setShow] = useState(true);
  const [disclaimer, setDisclaimer] = useState(true);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider
      value={{
        vendorProduct,
        setVendorProduct,
        setBankID,
        bankID,
        setShow,
        show,
        disclaimer,
        setDisclaimer,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
